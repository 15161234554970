@import url('https://fonts.googleapis.com/css?family=Abel');

$primary: #f37121; /* MAIN COLOR */
$secondary: #1e70af; /* SECONDARY COLOR */
$gray: #666; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$footerLinks: #fff;

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


body {
	font-size: 2em;
  font-family: 'Abel', sans-serif;


	h1,h2,h3,h4,h5,h6 {
		font-size:2em;
	}
}

nav {
	z-index: 10;
}
.navbar-brand {
    height: 100px; // this is what determine the height of the nav
    padding: 5px;
		@media(max-width: 767px){
			height: 70px; // nav height mobile,(feel free to change this values as you please)
			.logo {
				max-height: 60px; // logo height changed on mobile
			}
		}
}
.navbar .navbar-nav {
> li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: black;
			transition: 1s all ease;


			@media (max-width: 767px) {
				margin-top: 0;
			    display: inline-block;
			    height: 25px;
          padding: 3px 5px;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
}
.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

// here start mixins ---- this is a style for buttons you can add it to your a tag like this (@include btn, look at the .btn-default style), these values can be changed anytime you need to.
@mixin btn{
	text-align: center;
	margin: 0 auto;
	border-radius: 0px;
	background: $primary;
	color: $wht;
	padding: 0.7em 1em;
	font-size: 1em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 2em 0em;

	&:hover {
		background: $secondary;
		color: $wht;
		text-decoration: none;
    @media(max-width: 1024px){
      background: $primary;
    	color: $wht;
    }

	}
}

.btn-default {
	@include btn;
}
// mixins end here


// sections start
section{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 0;
  @media(max-width: 1024px){
    padding: 100px 0;
  }
  @media(max-width: 991px){
    padding: 80px 0;
  }

}
.section-a {
  background-color: lighten($secondary, 20%);
  color: $wht;
  p{
    margin-bottom: 1.5em;
  }

}
.section-b {
  background-color: lighten($blk, 35%);
  background-image: url('../img/bg2.jpg');
  color: $wht;
  .container {
    background-color: rgba(0, 0, 0, .8);
    padding: 60px;
    @media(max-width: 767px){
      padding: 30px;
    }
  }


}
.section-c {
  color: $blk;
  p,img {
    margin-bottom: 25px;
  }
  h2 {
    font-size: 1.5em;
    margin-bottom: 35px;
  }
  img  {
    box-shadow: 0px 0px 15px $secondary;
  }
}
.section-d {
  background-color: lighten($primary, 10%);
  color: $wht;
  .container {
    background-color: rgba(0, 0, 0, .8);
    padding: 60px;
    @media(max-width: 767px){
      padding: 30px;
    }
  }
  i {
    margin-right: 10px;
  }
  .contact-title {
    margin-top:45px;
  }
  a{
    color: $wht;
    text-decoration: none;
  }

}
// sections end


.modal-dialog {
	max-width: 500px;
	width: 100%;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	a.btn, a.btn-default, button {
		@include btn
		margin: 0;
		display: block;
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}


footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;

	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $footerLinks;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}



.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}
